<template>
    <div>
      <template v-if="isCurrentRoute">
        <h2>{{ $t('views.vouchers.discounts.title') }}</h2>
        <b-row class="mb-3" align-h="between">
          <b-col cols="auto"></b-col>
          <b-col cols="auto">
            <b-button variant="outline-primary" @click="navigate('Vouchers.Discounts.Add')"><b-icon icon="plus"/> {{ $t('views.vouchers.button.adddiscount') }}</b-button>
          </b-col>
        </b-row>
        <b-table :fields="table.fields" :items="entity.value.discounts" @row-clicked="tableRowClicked" hover striped tbody-tr-class="has-routerlink">
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(discountType)="data">
            {{ $t(`views.vouchers.discounts.discounttypes.${data.value.toLowerCase()}`) }}
          </template>
          <template #cell(amountType)="data">
            {{ $t(`views.vouchers.discounts.amounttypes.${data.value.toLowerCase()}`)}}
          </template>
          <template #cell(discountamount)="data">
            {{ data.item.amountType === 'Percent' ? `${data.item.percent} %` : `${data.item.amount} CHF` }}
          </template>
          <template #cell(condition)="data">
            <template v-if="data.item.amountType === 'Category'">{{ data.item.categoryId.join(', ') }}</template>
            <template v-if="data.item.amountType === 'Products'">{{ data.item.products.join(', ') }}</template>
            <template v-if="data.item.amountType === 'Families'">{{ data.item.families.join(', ') }}</template>
          </template>
        </b-table>
      </template>
      <!-- if subroute is active route (e.g. /users/detail) -->
      <section v-else>
        <router-view :entity="entity"/>
      </section>
    </div>
  </template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'

export default {
  name: 'Vouchers.Discounts',
  mixins: [MixinEntityBase],
  data () {
    return {
      table: {
        fields: [
          { key: 'index', label: 'Id' },
          { key: 'discountType', label: 'Rabatt Typ' },
          { key: 'amountType', label: 'Betrag Typ' },
          { key: 'discountamount', label: 'Rabatt' },
          { key: 'condition', label: 'Bedingung' }
        ]
      }
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntity`](this.eParent.id) || {}
    }
  },
  methods: {
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.eParent.key}/updateEntity`, entity)
    },
    tableRowClicked (item, index) {
      this.$router.push({ name: 'Vouchers.Discounts.Detail', params: { discountid: index } })
    }
  }
}
</script>
